import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {SubscribeComponent} from './subscribe.component';

import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';


@NgModule({
  declarations: [SubscribeComponent],
  imports: [
    HttpClientModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [SubscribeComponent],
  entryComponents: [SubscribeComponent],
})
export class SubscribePopupModule {
}
